* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  letter-spacing: -0.01rem;
}
html {
  font-size: 2.66vw;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.qxCTlb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent !important;
}

.process-card {
  clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
}

@media (min-width: 768px) {
html {
    font-size: .975vw;
}
}

@media (min-width: 1920px) {
html {
    font-size: 16px;
}
}

.preview-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container {
  width: 100%;
  height: 100%;
}

.menu {
  width: 100%;
  margin:0;
}

.menu-item {
  width: 100%;
  padding: 0 2em;
  display: flex;
  cursor: pointer;
}

.pp {
  position: absolute;
  top: 0%;
  width: 100%;
  transition: color 0.25s;
}

.info,
.tag,
.name {
  position: relative;
  overflow: hidden;
}

.info,
.tag {
  flex: 1;
  height: 14px;
  font-size: 14px;
}

.tag {
  text-align: right;
}

.name {
  flex: 4;
  height: 55px;
  font-size: 60px;
  text-align: center;
}

.info p:nth-child(2),
.name p:nth-child(2),
.tag p:nth-child(2) {
  top: 100%;
  color: currentColor;
  position: relative;
}

.menu:hover .info p:nth-child(1),
.menu:hover .name p:nth-child(1),
.menu:hover .tag p:nth-child(1) {
  color: rgb(255, 209, 174);
}

.preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 225px;
  height: 275px;
  z-index: 2;
  pointer-events: none;
}

.preview-img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.preview-img-2 {
  top: 20px;
  left: 20px;
}

.preview-img img {
  position: absolute;
  top: 0;
  left: 0;
}


.lg-nav-link:before {
    content: "\200B";
    float: left;
}

.lg-nav-link::after  {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    bottom: 0;
    height: 50%;
    background: black;
    transform: translateY(-50%);
    right: 0;
    width: 1px;
    /* border-radius: 10px; */
}


.lg-nav-link:last-child::after {
    position: absolute;
    right: 0;
    height: 0%;
    margin-left: auto;
    margin-right: auto;
}




.lg-hero-top-clip {
    clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%);
}

.faq-item .question {
    position: relative;
    transition: padding-left .8s cubic-bezier(.65,.05,.36,1);
    padding-right: 2vw;
    padding-left: 0;
}

.faq-item:hover .question {
    padding-left: 1vw;
}

.faq-item.active .question {
    padding-left: 1vw;
}

.faq-item .plus {
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 0;
    top: 0.176vw;
}

.faq-item .plus:before {
    content: "";
    position: absolute;
    background-color: currentColor;
    transition: opacity .4s cubic-bezier(.65,.05,.36,1),background-color .4s cubic-bezier(.65,.05,.36,1);
}
.faq-item .plus:before {
    left: 0;
    top: 50%;
background-color: currentColor;
    transform: translateY(-50%);
    width: 100%;
    height: 0.05rem;
}

.faq-item .plus:after {
left: 50%;
top: 0;
transform: translateX(-50%);
width: 0.05rem;
height: 100%;
}

.faq-item .plus:after, .faq-item .plus:before {
content: "";
position: absolute;
background-color: currentColor;
transition: opacity .4s cubic-bezier(.65,.05,.36,1),background-color .4s cubic-bezier(.65,.05,.36,1);
}  

.is-cursor {
    pointer-events: none;
}

.faq-item .question-line {
    transition: transform .6s cubic-bezier(.65,.05,.36,1),background .4s cubic-bezier(.65,.05,.36,1);
    position: absolute;
    transform-origin: 0 50%;
    transition-delay: 0ms;
    transform: scaleX(0);
    left: 0;
    top: 10px;
    width: 1.2vw;
    height: 1px;
    background: currentColor;
}

/* Target the 3rd and 4th .lg-ecom-item-title elements specifically */
.lg-ecom-item-title:nth-of-type(3),
.lg-ecom-item-title:nth-of-type(4) {
    padding-top: 4rem;
    /* background-color: var(--accent-or); */
}
